"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import ServicePage from "@/components/service/servicePage";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <ServicePage data={{
        title: "Something went wrong...",
        text: {
          content: [{
            type: "paragraph",
            content: [{
              type: "text",
              text: "An error has occurred and we're working to fix the problem."
            }],
            block_key: "123"
          }, {
            type: "paragraph",
            content: [{
              type: "text",
              text: "Please contact us at "
            }, {
              type: "link",
              url: "mailto:support@evidentinsights.com",
              text: "support@evidentinsights.com"
            }, {
              type: "text",
              text: " if you are seeing this page frequently or need immediate support."
            }],
            block_key: "123"
          }]
        },
        buttons: [{
          type: "link_button",
          id: "support",
          value: {
            theme: "primary",
            label: "Return to Evident",
            href: "/",
            open_in_new_tab: false
          }
        }]
      }} data-sentry-element="ServicePage" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}